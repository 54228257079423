import Head from 'next/head'
import config from 'global/config'

export default function Maintenance() {
  return (
    <div className="flex flex-wrap flex-col md:flex-col items-center">
      <Head>
        <title>Dimension X - The Game</title>
      </Head>
      {/* Left Col */}
      <div className="group w-full h-screen xl:w-3/5 flex flex-col justify-center content-center lg:items-start">
        <div className="flex flex-col sm:w-1/2 mx-auto text-center content-center bg-black opacity-90 rounded drop-shadow-lg">
          <div className="mt-20 px-10 pb-20">
            <h1 className="text-5xl">Under Maintenance!</h1>
            <p>🛠️️️ 🛠️️ 🛠️️️️</p>
            <p className="text-xl">
              We're fixing a few things and we'll be right back.  Sorry for the inconvenience!
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

Maintenance.getLayout = function getLayout(children) {
  return (
    <div className="w-full h-full text-white"
      style={{
        backgroundImage: `url(${config.WEBCDN_URL}/images/DMX_Landing_BG.png)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundAttachment: 'fixed',
      }}
    >
      <Head>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
      </Head>
      <div className="container md:w-3/4 h-full flex flex-col justify-between mx-auto">
        {children}
      </div>
    </div>
  )
}